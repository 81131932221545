import { Injectable, OnDestroy } from '@angular/core';
import { HubConnection } from '@node_modules/@aspnet/signalr/dist/esm';
import { SignalRAspNetCoreHelper } from '@shared/helpers/SignalRAspNetCoreHelper';
import { StockAndProductPriceInformationDto } from '@shared/service-proxies/service-proxies';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NotificationService implements OnDestroy {
    private updateProductCacheSubject = new Subject<boolean>();
    private updateProductPricesSubject = new Subject<string>();
    private downloadnProductsReportSubject = new Subject<any>();
    private downloadnSellerMonthlyReportSubject = new Subject<any>();
    private downloadProductInfoReportSubject = new Subject<any>();
    private stockInformationSubject = new Subject<StockAndProductPriceInformationDto>();
    private closeUserConnectionSubject = new Subject<boolean>();

    updateProductCache$ = this.updateProductCacheSubject.asObservable();
    updateProductPrices$ = this.updateProductPricesSubject.asObservable();
    donwloadProductReport$ = this.downloadnProductsReportSubject.asObservable();
    downloadnSellerMonthly$ = this.downloadnSellerMonthlyReportSubject.asObservable();
    stockListInformation$ = this.stockInformationSubject.asObservable();
    downloadProductInfoReport$ = this.downloadProductInfoReportSubject.asObservable();
    closeUserConnection$ = this.closeUserConnectionSubject.asObservable();

    private reconnectInterval: number = 5000; // 5 seconds
    private isManuallyDisconnected: boolean = false;
    private hubConnection: HubConnection;

    constructor() { 
        this.setupNotificationHub();
    }

    ngOnDestroy(): void {
    }

    private setupNotificationHub(): void {
        SignalRAspNetCoreHelper.initSignalR(() => {
            console.log('SignalR inicializado desde NotificationService.');
            abp.signalr.startConnection(abp.appPath + 'signalr-notificationHub', (connection) => {
                console.log('Configurando conexión SignalR en NotificationService.'); 
                this.hubConnection = connection;

                this.registerOnServerEvents(connection);
                connection.onclose(() => {
                    console.warn('Conexión SignalR cerrada. Intentando reconectar...');
                    if (!this.isManuallyDisconnected) {
                        this.reconnect();
                    }
                });
            })
            .then(() => {
                console.log('Conexión SignalR establecida en NotificationService.');
                abp.event.trigger('notificationHub.connected');
            })
            .catch((err) => {
                console.error('Error al conectar SignalR desde NotificationService:', err);
                this.reconnect();
            });
        });
    }

    private reconnect(): void {
        console.log('Conexión perdida. Intentando reconectar...');
        setTimeout(() => this.setupNotificationHub(), this.reconnectInterval);
    }

    private registerOnServerEvents(connection : any): void {
        connection.on('updateProductCache', (message: boolean) => {
            this.updateProductCacheSubject.next(message);
        });

        connection.on('updateProductPrices', (message: string) => {
            this.updateProductPricesSubject.next(message);
        });

        connection.on('downloadProductsReport', (excelData: any) => {
            this.downloadnProductsReportSubject.next(excelData);
        });

        connection.on('downloadSellerMonthlyReport', (excelData: any) => {
            this.downloadnSellerMonthlyReportSubject.next(excelData);
        });

        connection.on('downloadExcelProductInformation', (excelData: any) => {
            this.downloadProductInfoReportSubject.next(excelData);
        });

        connection.on('closeUserSession', (closeConnection: boolean) => {
            this.closeUserConnectionSubject.next(closeConnection);
        })
    }

    onUpdateProductCache(): Observable<boolean> {
        return this.updateProductCache$;
    }

    onUpdateProductPrices(): Observable<string> {
        return this.updateProductPrices$;
    }

    onDownloadProductsReport(): Observable<any> {
        return this.donwloadProductReport$;
    }

    onDownloadSellerMonthlyReport(): Observable<any> {
        return this.downloadnSellerMonthly$;
    }

    onDownloadProductInfoReport(): Observable<any> {
        return this.downloadProductInfoReport$;
    }

    onStockOrPriceChange(key: string): Observable<any> {
        this.hubConnection.on(key, (data: StockAndProductPriceInformationDto) => {
            this.stockInformationSubject.next(data);
        });
        return this.stockListInformation$;
    }

    onCloseUserConnection(): Observable<boolean>{
        return this.closeUserConnection$;
    }
}
