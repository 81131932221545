import { Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionState } from '@aspnet/signalr';
import { SignalRAspNetCoreHelper } from '@shared/helpers/SignalRAspNetCoreHelper';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PrinterService implements OnDestroy {
  private hubConnection: HubConnection;
  private updateInvoiceSubject = new Subject<boolean>();
  private sendNumberSubject = new Subject<any>();
  private updatePrintersSubject = new Subject<boolean>();
  private paymentReceivedSubject = new Subject<boolean>();
  private reconnectInterval: number = 5000; // 5 segundos
  private isManuallyDisconnected: boolean = false; // Nueva bandera

  updateInvoice$ = this.updateInvoiceSubject.asObservable();
  sendNumber$ = this.sendNumberSubject.asObservable();
  updatePrinters$ = this.updatePrintersSubject.asObservable();
  paymentReceived$ = this.paymentReceivedSubject.asObservable();

  constructor() {
    this.setupPrinterHub();
  }

  private ensureConnected(): void {
    if (!this.hubConnection || this.hubConnection.state !== HubConnectionState.Connected) {
      this.setupPrinterHub();
    }
  }

  private connect(resolve: () => void, reject: (err: any) => void): void {
    SignalRAspNetCoreHelper.initSignalR(() => {
      abp.signalr.startConnection('/signalr-printerHub', (connection) => {
        this.hubConnection = connection;
        this.registerOnServerEvents(connection);
        this.hubConnection.onclose(() => {
          if (!this.isManuallyDisconnected) {
            this.reconnect();
          }
        });
        resolve();
      }).catch(err => {
        console.error('Error al iniciar la conexión SignalR: ', err);
        setTimeout(() => this.connect(resolve, reject), this.reconnectInterval);
        reject(err);
      });
    });
  }

  private setupPrinterHub(): void {
    SignalRAspNetCoreHelper.initSignalR(() => {
        console.log('SignalR inicializado desde PrinterService.');
        abp.signalr.startConnection(abp.appPath + 'signalr-printerHub', (connection) => {
            console.log('Configurando conexión SignalR en PrinternService.'); 
            this.hubConnection = connection;

            this.registerOnServerEvents(connection);
            connection.onclose(() => {
                console.warn('Conexión SignalR cerrada. Intentando reconectar...');
                if (!this.isManuallyDisconnected) {
                    this.reconnect();
                }
            });
        })
        .then(() => {
            console.log('Conexión SignalR establecida en PrinterService.');
            abp.event.trigger('printerHub.connected');
        })
        .catch((err) => {
            console.error('Error al conectar SignalR desde PrinterService:', err);
            this.reconnect();
        });
    });
}

  private reconnect(): void {
    console.log('Conexión perdida. Intentando reconectar...');
    setTimeout(() => this.setupPrinterHub(), this.reconnectInterval);
  }

  private registerOnServerEvents( connection: any): void {
    connection.on('updateInvoices', (message: boolean) => {
      this.updateInvoiceSubject.next(message);
    });

    connection.on('sendNumber', (number: any) => {
      this.sendNumberSubject.next(number);
    });

    connection.on('updatePrinters', (message: boolean) => {
      this.updatePrintersSubject.next(message);
    });

    connection.on('paymentReceived', (message: boolean) => {
      this.paymentReceivedSubject.next(message);
    });
  }

  disconnect(): void {
    this.isManuallyDisconnected = true; // Marca la desconexión manual
    if (this.hubConnection && this.hubConnection.state !== HubConnectionState.Disconnected) {
      this.hubConnection.stop()
        .then(() => {
          console.log('Desconectado del hub de SignalR');
        })
        .catch(err => console.error('Error al desconectar la conexión de SignalR: ', err));
    }
  }

  ngOnDestroy(): void {
    this.disconnect(); // Llama a desconectar al destruir el servicio
  }

  onUpdateInvoices(): Observable<boolean> {
    this.ensureConnected(); // Asegurarse de que está conectado
    return this.updateInvoice$;
  }

  onSendNumber(): Observable<any> {
    this.ensureConnected(); // Asegurarse de que está conectado
    return this.sendNumber$;
  }

  onUpdatePrinters(): Observable<boolean> {
    this.ensureConnected(); // Asegurarse de que está conectado
    return this.updatePrinters$;
  }

  onPaymentReceived(): Observable<boolean> {
    this.ensureConnected(); // Asegurarse de que está conectado
    return this.paymentReceived$;
  }
}
